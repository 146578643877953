<template>
  <div class="bottom-info-wraper">
    <div class="other-info">
      <div class="list">
        <div class="item">帮助</div>
        <div class="item">隐私</div>
        <div class="item">条款</div>
      </div>
      <div class="info">Copyright 2018-2022 京东科技版权 jdt.com.cn 所有</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data: () => {
    return {
    };
  },
  watch: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.bottom-info-wraper {
  width: 100%;
  background: #f9f9f9;

  .other-info {
    width: 100%;
    height: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .list {
      font-size: 12px;
      font-weight: 300;
      color: #393F49;
      display: flex;
      .item {
        height: 13px;
        border-left: 1px solid #393F49;
        padding: 0 20px;
        cursor: pointer;
      }

      .item:nth-child(1) {
        border-left: unset;
      }
    }

    .info {
      font-size: 12px;
      line-height: 20px;
      font-weight: 300;
      color: #393F49;
      margin-top: 9px;
    }
  }
}
</style>
