<template>
  <div>
    <div class="top">
      <TopBar
        @menuMouseover="menuMouseover"
        @menuMouseleave="menuMouseleave"
        ref="topCom"
        :isBlack="true"
        :userInfo="userInfo"
      />
    </div>
    <ProjectMenu
      class="project-menu-area"
      v-show="projectMenuShow"
      :hiddenFun="hiddenProjectMenu"
      :stopFun="stopFun"
      :menuList="menuList"
    />
    <div class="banner">
      <div class="bannercenter">
        <div class="card">
          <div class="scene">文档中心</div>
          <div class="u"></div>
          <p>快速定位产品，便携浏览内容</p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="list" v-for="(item, index) in platformInfo" :key="index">
        <div class="headList" @click="goLinkItem(item.themeUrl)">
          <p>
            <img class="platform-picture" :src="item.imgUrl" />
            <span class="platform-name">{{ item.themeName }}</span>
          </p>
        </div>
        <ul class="dataList">
          <li
            v-for="doc in item.documents"
            :key="doc.docId"
            v-show="doc.dataStatus === 1"
            @click="goLinkItem(doc.docUrl)"
          >
            {{ doc.docName }}
          </li>
        </ul>
      </div>
    </div>
    <div class="fotter"><Bottom /></div>
  </div>
</template>

<script>
import { findAll2 } from "@/server/api";
import TopBar from "@/components/TopBar.vue";
import ProjectMenu from "@/components/ProjectMenu.vue";
import Bottom from "./component/Bottom.vue";
export default {
  data() {
    return {
      userInfo: {},
      platformInfo: [],
      projectMenuShow: false,
      menuList: [],
      imgs: [
        require("../assets/helpImg/BDP.png"),
        require("../assets/helpImg/shishijisuan.png"),
        require("../assets/helpImg/zhiBiaoHuaXiang.png"),
        require("../assets/helpImg/tuJisuan.png"),
        require("../assets/helpImg/jiQiXueXi.png"),
        require("../assets/helpImg/basicService.png"),
        require("../assets/helpImg/dataService.png"),
        require("../assets/helpImg/zhiBiaoHuaXiang.png"),
      ],
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.$http.get("/api/support/help/doc/queryAll").then((res) => {
      this.platformInfo = res.data.rspData;
    });
  },
  components: {
    TopBar,
    ProjectMenu,
    Bottom,
  },
  async mounted() {
    
    const result = await findAll2();
    this.menuList = result.data.rspData;
  },
  methods: {
    goLinkItem(themeUrl) {
      window.open(`${location.origin}${themeUrl}`);
    },
    stopFun() {
      clearTimeout(this.timer);
    },
    menuMouseover(code) {
      if (code === "produce") {
        this.projectMenuShow = true;
      }
    },
    menuMouseleave(code) {
      if (code === "produce") {
        this.timer = setTimeout(() => {
          this.projectMenuShow = false;
          this.$refs.topCom.selectedProduce(false);
        }, 200);
      }
    },
    hiddenProjectMenu() {
      this.projectMenuShow = false;
      this.$refs.topCom.selectedProduce(false);
    },
  },
};
</script>
<style lang='scss' scoped>
.top {
  overflow: hidden;
  height: 60px;
}
a {
  text-decoration: none;
  color: #393f49;
}
.banner {
  height: 240px;
  background-size: 100% 100%;
  background-image: url(../assets/helpImg/documentBanner.png);
  position: relative;
  padding-top: 0px;
  .bannercenter {
    width: 62.5%;
    margin: 0 auto;
    height: 240px;
    position: relative;
    .card {
      position: absolute;
      left: 0px;
      top: 64px;
      width: 330px;
      height: 142px;
      box-sizing: border-box;
      padding-left: 30px;
      padding-top: 30px;
      background: rgba(255, 255, 255, 0.68);
      .scene {
        font-size: 26px;
        margin-bottom: 16px;
      }
      .u {
        width: 124px;
        height: 1px;
        background: red;
      }
      p {
        margin-top: 16px;
        color: #858a94;
        font-size: 16px;
      }
    }
  }
}
.content {
  width: 1200px;
  margin: 0 auto;
  min-height: 600px;
  padding-top: 38px;
  .list {
    margin-bottom: 30px;
    .headList {
      display: flex;
      height: 46px;
      line-height: 46px;
      padding-bottom: 6px;
      cursor: pointer;
      .platform-picture {
        display: inline-block;
        width: 46px;
        height: 46px;
        vertical-align: middle;
      }
      .platform-name {
        display: inline-block;
        font-size: 18px;
        color: #393f49;
        margin-left: 12px;
        font-weight: 500;
        &:hover {
          color: rgba(201, 4, 42, 1);
        }
        vertical-align: middle;
      }
    }
    ul.dataList {
      width: 1200px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      border: 1px solid #eeeff3;
      padding: 16px 9px;
      flex-wrap: wrap;
      li {
        box-sizing: border-box;
        list-style: none;
        width: 240px;
        height: 20px;
        line-height: 20px;
        margin: 8px 27px;
        font-size: 14px;
        color: #020305;
        cursor: pointer;
        &:hover {
          color: rgba(201, 4, 42, 1);
        }
      }
    }
  }
}
.fotter {
  height: 88px;
  margin-top: 64px;
}
</style>
